<template>
  <app-form-autocomplete
    v-model="model"
    v-model:query="query"
    data-cy-organization
    :hint="hint"
    item-title="item.name"
    item-value="item.id"
    :items="results"
    label="Organization"
    :readonly="readonly"
    :required="required"
    :return-object="returnObject"
  >
    <template #item="{ item: value, props }">
      <v-list-item v-bind="props" :title="value.item.name">
        <template #prepend>
          <app-image
            :alt="value.item.name"
            class="mr-4"
            :height="25"
            :src="value.item.logo_url"
            :width="25"
          />
        </template>
      </v-list-item>
    </template>
  </app-form-autocomplete>
</template>

<script lang="ts" setup>
import { useFuse } from "@vueuse/integrations/useFuse";
const model = defineModel<DatabaseTable<"organizations">["id"]>();

defineProps<{
  required?: boolean;
  returnObject?: boolean;
  readonly?: boolean;
  hint?: string;
}>();

const { data } = useAsyncData(
  async () => {
    const { data } = await useDatabaseClient().from("organizations").select();

    return data ?? [];
  },
  {
    default: () => [],
  },
);

const query = ref("");
const { results } = useFuse(query, data, {
  matchAllWhenSearchEmpty: true,
  fuseOptions: { keys: ["name"], threshold: 0.2 },
});
</script>
